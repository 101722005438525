.rbc-row-bg :hover {
    background-color: #eaf6ff;
    cursor: pointer;
}

.rbc-day-bg-disable {
    background-color: #e6e6e6;
}

.service-bg:hover {
    cursor: pointer;
}