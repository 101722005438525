.rbc-day-bg :hover {
    background-color: #eaf6ff;
}

.rbc-today {
    background-color: white;
}

.rbc-date-cell.rbc-now {
    font-weight: bold;
    font-size: 18;
    color: blue;
}

.rbc-agenda-date-cell {
    background-color: white;
    border: 1px solid #ddd;
}

.rbc-time-header-content .rbc-row-resource {
    border-bottom: none !important;
}

.rbc-time-header-content .rbc-row-resource .rbc-header {
    border-bottom: none !important;
    margin: 10px 0px 10px 0px;
}

.rbc-time-header-content .rbc-allday-cell {
    display: none;
}

.rbc-events-container {
    margin-right: 0px !important;
}

.rbc-events-container .has-event {
    width: 100% !important;
    left: 0% !important;
}

.rbc-events-container .tiny .rbc-event-label {
    display: none;
    color: #000;
    font-size: 70% !important;
    margin-top: -3px;
}

.rbc-events-container .no-event {
    background: #ffffff;
    border-bottom: 1px solid #DDD;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0px;
    width: 100% !important;
    left: 0% !important;
}

.rbc-events-container .no-event .rbc-event-label {
    display: none;
    color: #000;
    font-size: 80%;
    margin-top: -2px;
}

.rbc-events-container .no-event:hover {
    background: #f4db5f !important;
}

.rbc-events-container .no-event:hover .rbc-event-label {
    display: inline;
}

.rbc-events-container .mobile-no-event {
    background: #ffffff;
    border-bottom: 1px solid #DDD;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0px;
    width: 100% !important;
    left: 0% !important;
}

.rbc-events-container .mobile-no-event .rbc-event-label {
    display: inline !important;
    color: dimgray;
    font-size: 50%;
    margin-top: -2px;
}

.rbc-events-container .time-unavailable {
    background-color: #efefef !important;
}

.rbc-events-container .rbc-event-content {
    font-size: 90%;
}

.rbc-off-range-bg {
    background: #f5f5f5;
}

.rbc-time-gutter .rbc-time-slot {
    background: #ffffff !important;
}

.rbc-time-view-resources .rbc-time-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 4;
    background: white;
    border-top: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
}

.rbc-time-content {
    border-top: none;
}

.rbc-time-view {
    border-bottom: none;
}

.rbc-time-header-content + .rbc-time-header-content {
    margin-left: 0px;
}

.rc-calendar {
    border: none !important;
    box-shadow: none !important;
    font-size: 15px;
    color: black;
    margin: auto;
    width: 270px;
}

.rbc-day-slot .rbc-event {
    min-height: 10px;
}

.store-no-event {
    font-size: 50%;
    color: dimgray;
    background-color: #ffffff;
}

.store-no-event span {
    margin-left: 5px;
}

.store-time-unavailable {
    background-color: #efefef;
}